import React, { Component } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiThemeProvider, createMuiTheme } from "@material-ui/core/styles";
import Router from "./Router";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: `#a7dc72`,
      main: `#76AA43`,
      dark: `#467b12`,
      contrastText: "#fff"
    },
    secondary: {
      light: `#ff5a45`,
      main: `#ff0019`,
      dark: `#c20000`,
      contrastText: "#fff"
    }
  },
  typography: {
    // Use the system font over Roboto.
    fontFamily: `'Noto Sans JP', sans-serif`,
    useNextVariants: true
  }
});

class App extends Component {
  render() {
    return (
      <MuiThemeProvider theme={theme}>
        <CssBaseline />
        <Router />
      </MuiThemeProvider>
    );
  }
}

export default App;
