import React, { Component } from "react";
import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import "firebase/functions";
import "firebase/messaging";
import "firebase/performance";
import "firebase/analytics";
import CircularProgress from "@material-ui/core/CircularProgress";
import Scroll from "react-scroll";
import createBrowserHistory from "history/createBrowserHistory";
import { FirebaseConfig } from "./firebase/config";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect
} from "react-router-dom";
import moment from "moment";
import "moment/locale/ja";
moment.locale('ja');

if (!firebase.apps.length) {
  firebase.initializeApp(FirebaseConfig);
}

// アナリティクス設定
const analytics = firebase.analytics();
const history = createBrowserHistory();
history.listen(({ pathname }) => {
  analytics.logEvent("page_view");
});
/*
ReactGA.initialize('UA-170004830-1');
const history = createBrowserHistory();
history.listen(({ pathname }) => {
  ReactGA.set({ page: pathname });
  ReactGA.pageview(pathname);
});
*/

const auth = firebase.auth();
const auth2 = firebase.auth;
const googleauth = new firebase.auth.GoogleAuthProvider();
const storage = firebase.storage();
const db = firebase.firestore();

// const message = firebase.messaging();
const functions = firebase.functions();
//const perf = firebase.performance();

const SScroller = Scroll.scroller;
const AScroller = Scroll.animateScroll;

class RouterDiv extends Component {
  state = {
    user: null,
    auth: auth,
    auth2: auth2,
    googleauth: googleauth,
    headerShow: false,
    messageShow: false,
    localuser: null,
    qualification_List: [],
    usersqualification_List: [],
    userscoursedata_List: [],
    usersdiploma_Lit: [],
    localuser_DB: null,
    localuser_ST: null,
    openContent: false,
    isAutoLogin: false,
    prevpath: null,
    contentWindow: null,
    course: null,
    Header: null,
    UserMenu: null,
    LoginPage: null,
    CourseDetailPage: null,
    UserSettingPage: null,
    StartPage: null,
    TopPage: null,
    NoMatchPage: null,
    QualificationPage: null,
    ReceiptPage: null,
    ContentPage: null,
    MailAction: null,
    TermsPage: null,
    PayResultPage: null,
    AdminPage: null,
    DiplomaPage: null,
    HelpPage: null,
    SelectGroupPage: null,
    targetGroup: {},
    groupOutOfRange: false,
    groupMenuList: [],
    leaderlicenseId: null
  };

  users_Snapshot = null;
  userscoursedata_Snapshot = null;
  usersqualification_Snapshot = null;
  usersdiploma_Snapshot = null;
  qualification_Snapshot = null;

  componentWillMount = async () => {
    const [
      Header,
      UserMenu,
      LoginPage,
      CourseDetailPage,
      UserSettingPage,
      StartPage,
      TopPage,
      NoMatchPage,
      QualificationPage,
      ReceiptPage,
      ContentPage,
      MailAction,
      TermsPage,
      PayResultPage,
      AdminPage,
      DiplomaPage,
      HelpPage,
      SelectGroupPage
    ] = await Promise.all([
      import("./component/Header"),
      import("./Pages/UserMenu"),
      import("./Pages/LoginPage"),
      import("./Pages/CourseDetailPage"),
      import("./Pages/UserSettingPage"),
      import("./Pages/StartPage"),
      import("./Pages/TopPage"),
      import("./Pages/NoMatchPage"),
      import("./Pages/QualificationPage"),
      import("./Pages/ReceiptPage"),
      import("./Pages/ContentPage"),
      import("./Pages/MailAction"),
      import("./Pages/Tearms"),
      import("./Pages/PayResult"),
      import("./Pages/AdminPage"),
      import("./Pages/DiplomaPage"),
      import("./Pages/Help"),
      import("./Pages/SelectGroupPage"),
    ]);
    this.setState({
      Header: Header.default,
      LoginPage: LoginPage.default,
      UserMenu: UserMenu.default,
      CourseDetailPage: CourseDetailPage.default,
      UserSettingPage: UserSettingPage.default,
      StartPage: StartPage.default,
      TopPage: TopPage.default,
      NoMatchPage: NoMatchPage.default,
      QualificationPage: QualificationPage.default,
      ReceiptPage: ReceiptPage.default,
      ContentPage: ContentPage.default,
      MailAction: MailAction.default,
      TermsPage: TermsPage.default,
      PayResultPage: PayResultPage.default,
      AdminPage: AdminPage.default,
      DiplomaPage: DiplomaPage.default,
      HelpPage: HelpPage.default,
      SelectGroupPage: SelectGroupPage.default,
    });
  };
  componentDidMount() {
    // ログイン状態監視
    firebase.auth().onAuthStateChanged(async (user) => {
      this.userStateFunc(user);
    });
  }

  userStateFunc = async(user) => {
    if (user) {
      this.setState({ isAutoLogin: true });
      const userRef = db.collection("users").doc(user.uid);
      const userStorageRef = storage.ref("/users").child(user.uid);
      try {
        const userdata = await userRef.get();
        if (!userdata.exists) {
          // 初回ログイン　かつ　ユーザ登録してから
          const groupsDefault = await db
            .collection("groups")
            .where("default", "==", true)
            .get();
          let groups = {};
          groupsDefault.forEach(function (doc) {
            const data = doc.data();
            if (!data.nogroup) {
              groups[doc.id] = true;
            }
          });
          const defaultUserSettings = {
            area: "",
            birthday: null,
            email: user.email,
            gender: "",
            job: "",
            romaname: "",
            kananame: "",
            name: "",
            sports: "",
            role: 'N',
            groups
          };
          await userRef.set(defaultUserSettings, { merge: true });
          this.setState({
            localuser: defaultUserSettings,
            localuser_DB:userRef,
            localuser_ST: userStorageRef
          });
        } else {
          this.setState({
            localuser: { id: userdata.id, ...userdata.data() },
            localuser_DB:userRef,
            localuser_ST: userStorageRef
          });
        }
        // ユーザデータ更新監視
        this.users_Snapshot = userRef.onSnapshot(async (doc) => {

          let localuser =  doc.data();

          // グループリスト
          let groupMenuList = [];
          let targetGroup = {};
          let groupOutOfRange = false;
          if(localuser.role === 'G'){
            const groupsDB = await db.collection("groups")
            .where("owners", "array-contains", user.uid)
            .get();
            groupsDB.forEach((doc) => {
              groupMenuList.push({id:doc.id, ...doc.data()});
            });
            if(groupMenuList.length === 1){
              targetGroup = groupMenuList[0];
              if(targetGroup.start_date && moment.unix(targetGroup.start_date).format("YYYYMMDD") > moment().format("YYYYMMDD")){
                groupOutOfRange = true;
              } 
              if(targetGroup.end_date && moment.unix(targetGroup.end_date).format("YYYYMMDD") < moment().format("YYYYMMDD")){
                groupOutOfRange = true;
              }
            }
          }
          if(localuser.role === 'A'){
            const allGroup = {id:'ALL',name:'すべて'};
            groupMenuList.push(allGroup);
            const groupsDB =  await db.collection("groups").get();
            groupsDB.forEach((doc) => {
              let groupudata = doc.data();
              if(groupudata.maingroupid){
                return;
              }
              groupMenuList.push({id:doc.id, ...doc.data()});
            });
            targetGroup = allGroup;
          }

          this.setState({ 
            localuser,
            groupMenuList,
            targetGroup,
            groupOutOfRange
          });
        });

        // 資格データ更新監視
        this.qualification_Snapshot = db
          .collection("qualification")
          .orderBy("qid", "asc")
          .onSnapshot((qsnapshot) => {
            let qualification_List = [];
            let leaderlicenseId = '';
            if (qsnapshot.size > 0) {
              qsnapshot.forEach((doc) => {
                let docData = doc.data();
                qualification_List.push({ id: doc.id, ...docData });
                if(docData.name === 'リーダー') leaderlicenseId = doc.id;
              });
            }
            this.setState({
              qualification_List,
              leaderlicenseId
            });
          });

        // ユーザ資格データ更新監視
        this.usersqualification_Snapshot = db
          .collection("usersqualification")
          .onSnapshot((uqsnapshot) => {
            let usersqualification_List = [];
            if (uqsnapshot.size > 0) {
              uqsnapshot.forEach((doc) => {
                let uq_data = doc.data();
                usersqualification_List.push({ id: doc.id, ...uq_data });
              });
            }
            this.setState({
              usersqualification_List
            });
          });

        // ユーザコースデータ更新監視
        this.userscoursedata_Snapshot = db
          .collection("userscoursedata")
          .where("uid", "==", user.uid)
          .onSnapshot((udsnapshot) => {
            let userscoursedata_List = [];
            if (udsnapshot.size > 0) {
              udsnapshot.forEach((doc) => {
                userscoursedata_List.push({ id: doc.id, ...doc.data() });
              });
            }
            this.setState({
              userscoursedata_List
            });
          });

        // ユーザ修了証・出席証更新監視
        this.usersdiploma_Snapshot = db
        .collection("usersdiploma")
        .where("uid", "==", user.uid)
        .onSnapshot((ulsnapshot) => {
          let usersdiploma_List = [];
          if (ulsnapshot.size > 0) {
            ulsnapshot.forEach((doc) => {
              usersdiploma_List.push({ id: doc.id, ...doc.data() });
            });
          }
          this.setState({
            usersdiploma_List
          });
        });

        if (!user.emailVerified && this.state.localuser.role !== 'A') {
          this.setState({
            messageShow: true,
            isAutoLogin: false
          });
          return;
        }
        this.setState({
          user: user,
          isAutoLogin: false,
        });
        analytics.setUserId(user.uid);
      } catch (error) {
        console.log(error);
        this.setState({
          isAutoLogin: false
        });
      }
    } else {
      if (this.users_Snapshot) this.users_Snapshot();
      if (this.qualification_Snapshot) this.qualification_Snapshot();
      if (this.usersqualification_Snapshot) this.usersqualification_Snapshot();
      if (this.userscoursedata_Snapshot) this.userscoursedata_Snapshot();
      if (this.usersdiploma_Snapshot) this.usersdiploma_Snapshot();
      this.setState({
        user: null,
        localuser: null,
        usersqualification_List: [],
        userscoursedata_List: [],
        qualification_List: [],
        usersdiploma_List: [],
        localuser_DB: null,
        localuser_ST: null,
        isAutoLogin: false
      });
    }
  }

  componentWillUnmount() {
  }

  alertLogin = () => {
    if (!this.state.user) {
      return alert("ログインが必要です。");
    }
    if (
      this.state.localuser.name === "" ||
      this.state.localuser.kananame === ""
    ) {
      return alert("ユーザ情報が未設定です。");
    }
  };

  openContentPage(course, history, location) {
    if (!this.state.user) {
      return alert("ログインが必要です。");
    }
    if (
      this.state.localuser.name === "" ||
      this.state.localuser.kananame === ""
    ) {
      return alert("ユーザ情報が未設定です。");
    }
    const agent = window.navigator.userAgent.toLowerCase();
    const ie11 = agent.indexOf("trident/7") !== -1;
    // const ie11 = false;
    const nowContent = ie11
      ? null
      : window.open(
          course.contents,
          "blank"
          
        );
    this.setState({
      contentWindow: nowContent,
      openContent: true,
      course: course,
      prevpath: location
    });
    history.push(`/member/user/contents/${course.id}`);
  }

  closeContentPage = (history) => (event) => {
    history.replace(this.state.prevpath);
    this.setState({
      openContent: false,
      course: null,
      prevpath: null
    });
  };

  headerSpy(bool) {
    this.setState({
      headerShow: bool
    });
  }

  gotoUserPage = (history) => async (e) => {
    await history.push("/member/user");
    await window.location.reload();
  };

  messageHide = () => {
    this.setState({
      messageShow: false
    });
  };

  verifiedCancel = () => {
    this.setState({
      messageShow: false
    });
  };

  scrollTo = (name = null) => (event) => {
    if (name) {
      SScroller.scrollTo(name, {
        smooth: true,
        offset: -62,
        duration: 400
      });
    } else {
      AScroller.scrollTo(0);
    }
  };

  groupMailCount = (data) => {
    const { localuser } = this.state;
    let targetGroup = this.state.targetGroup;
    if(targetGroup.id === 'ALL') return;
    if(localuser.role === 'G'){
      let sendmailcount = targetGroup.sendmailcount || 0;
      sendmailcount += data.to.length;
      targetGroup.sendmailcount = sendmailcount;
      db.collection("groups").doc(targetGroup.id).set({ sendmailcount }, { merge: true });
      this.setState({
        targetGroup
      });
    }
  }

  selectGroupFunc = (id) => {
    const { localuser } = this.state;
    let glist = this.state.groupMenuList.filter(g => g.id === id);
    let groupOutOfRange = false;
    let targetGroup = glist[0];
    if(localuser.role === 'G'){
      if(targetGroup.start_date && moment.unix(targetGroup.start_date).format("YYYYMMDD") > moment().format("YYYYMMDD")){
        groupOutOfRange = true;
      } 
      if(targetGroup.end_date && moment.unix(targetGroup.end_date).format("YYYYMMDD") < moment().format("YYYYMMDD")){
        groupOutOfRange = true;
      }
    }
    this.setState({
      targetGroup,
      groupOutOfRange
    });
  }

  debugAccountChange = async (uid) => {

    // ユーザー情報取得
    const userRef = db.collection("users").doc(uid);
    const userdoc = await userRef.get();
    if (userdoc.exists) {
      let udata = userdoc.data();
      let user = {};
      user.uid = uid;
      user.displayName = udata.name;
      user.email = udata.email;
      user.photoURL = '';
      console.log('debugAccountChange:userid',uid);
      console.log('debugAccountChange:user',user);
      this.userStateFunc(user);
    }


  }

  render() {
    const {
      Header,
      LoginPage,
      UserMenu,
      CourseDetailPage,
      UserSettingPage,
      StartPage,
      TopPage,
      NoMatchPage,
      QualificationPage,
      ReceiptPage,
      ContentPage,
      MailAction,
      TermsPage,
      PayResultPage,
      AdminPage,
      DiplomaPage,
      HelpPage,
      SelectGroupPage,
      openContent,
      qualification_List,
      userscoursedata_List,
      usersqualification_List,
      usersdiploma_List,
      groupMenuList,
      targetGroup,
      groupOutOfRange,
      leaderlicenseId,
    } = this.state;
    if (Header) {
      return (
        <Router>
          <div style={{ height: "100%" }}>
            <Header
              user={this.state.user}
              auth={this.state.auth}
              headershow={this.state.headerShow}
              localuser={this.state.localuser}
              isAutoLogin={this.state.isAutoLogin}
              scrollTo={this.scrollTo}
              groupMenuList={groupMenuList}
              targetGroup={targetGroup}
              selectGroupFunc={this.selectGroupFunc}
              debugAccountChange={this.debugAccountChange}
              qualification_List={qualification_List}
              usersqualification_List={usersqualification_List}
            />
            <Switch>
              <Route
                exact
                path="/"
                render={(props) => {
                  return (
                    <TopPage
                      headerspy={(bool) => {
                        this.headerSpy(bool);
                      }}
                      scrollTo={this.scrollTo}
                      {...props}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/help"
                render={(props) => {
                  return <HelpPage {...props} />;
                }}
              />
              <Route
                exact
                path="/top/:toppage"
                render={(props) => {
                  return (
                    <StartPage
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      localuser={this.state.localuser}
                      localuser_DB={this.state.localuser_DB}
                      userscoursedata_List={userscoursedata_List}
                      qualification_List={qualification_List}
                      usersqualification_List={usersqualification_List}
                      headerspy={(bool) => {
                        this.headerSpy(bool);
                      }}
                      openContentPage={(id) => {
                        this.openContentPage(id, props.history, props.location);
                      }}
                      alertLogin={this.alertLogin}
                      {...props}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/login"
                render={(props) => {
                  return this.state.user ? (
                    <Redirect
                      to={{
                        pathname: "/member/user"
                      }}
                    />
                  ) : (
                    <LoginPage
                      auth={this.state.auth}
                      messageShow={this.state.messageShow}
                      messageHide={this.messageHide}
                      verifiedCancel={this.verifiedCancel}
                      googleauth={this.state.googleauth}
                      isAutoLogin={this.state.isAutoLogin}
                      {...props}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/user"
                render={(props) => {
                  return this.state.user ? (
                    <UserMenu
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      functions={functions}
                      localuser={this.state.localuser}
                      localuser_DB={this.state.localuser_DB}
                      openContentPage={(id) => {
                        this.openContentPage(id, props.history, props.location);
                      }}
                      alertLogin={this.alertLogin}
                      userscoursedata_List={userscoursedata_List}
                      qualification_List={qualification_List}
                      usersqualification_List={usersqualification_List}
                      usersdiploma_List={usersdiploma_List}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login"
                      }}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/user/settings"
                render={(props) => {
                  return this.state.user ? (
                    <UserSettingPage
                      auth={this.state.auth}
                      auth2={auth2}
                      db={db}
                      localuser={this.state.localuser}
                      user={this.state.user}
                      storage={storage}
                      firebase={firebase}
                      functions={functions}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login"
                      }}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/user/qualification/:qualificationId"
                render={(props) => {
                  return this.state.user ? (
                    <QualificationPage
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      localuser={this.state.localuser}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login"
                      }}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/user/receipt/:receiptId"
                render={(props) => {
                  return this.state.user ? (
                    <ReceiptPage
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      localuser={this.state.localuser}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login"
                      }}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/user/diploma/:diplomaId"
                render={(props) => {
                  return this.state.user ? (
                    <DiplomaPage
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      localuser={this.state.localuser}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login"
                      }}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/user/contents/:contentsId"
                render={(props) => {
                  return this.state.user && this.state.course ? (
                    <ContentPage
                      open={openContent}
                      user={this.state.user}
                      db={db}
                      course={this.state.course}
                      contentWindow={this.state.contentWindow}
                      closeContentPage={this.closeContentPage(props.history)}
                      {...props}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login"
                      }}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/courses/:id/:dispmode"
                render={(props) => {
                  const { classes, ...other } = props;
                  return (
                    <CourseDetailPage
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      localuser={this.state.localuser}
                      openContentPage={(id) => {
                        this.openContentPage(id, props.history, props.location);
                      }}
                      alertLogin={this.alertLogin}
                      userscoursedata_List={userscoursedata_List}
                      qualification_List={qualification_List}
                      usersqualification_List={usersqualification_List}
                      {...other}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/termsofservice"
                render={(props) => {
                  const { classes, ...other } = props;
                  return <TermsPage {...other} />;
                }}
              />
              <Route
                exact
                path="/member/auth/action"
                render={(props) => {
                  const { classes, ...other } = props;
                  return (
                    <MailAction
                      auth={this.state.auth}
                      db={db}
                      user={this.state.user}
                      gotoUserPage={this.gotoUserPage}
                      {...other}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/admin"
                render={(props) => {
                  const { classes, ...other } = props;
                  const { user, localuser, groupMenuList, targetGroup } = this.state;
                  return user && localuser && Object.keys(targetGroup).length === 0 ?
                  (
                    <Redirect 
                    {...props}
                      to={{
                        pathname: "/member/selectgrouppage"
                      }}
                    />
                  ) :
                  user && localuser && localuser.role && (localuser.role === 'G' || localuser.role === 'A') ? (
                    <AdminPage
                      auth={this.state.auth}
                      firebase={firebase}
                      storage={storage}
                      db={db}
                      user={user}
                      functions={functions}
                      localuser={localuser}
                      groupMenuList={groupMenuList}
                      targetGroup={targetGroup}
                      groupOutOfRange={groupOutOfRange}
                      usersqualification_List={usersqualification_List}
                      leaderlicenseId={leaderlicenseId}
                      groupMailCount={this.groupMailCount}
                      {...other}
                    />
                  ) : (
                    <Redirect
                      to={{
                        pathname: "/member/login"
                      }}
                    />
                  );
                }}
              />
              <Route
                exact
                path="/member/paysuccess"
                render={(props) => {
                  return <PayResultPage {...props} status="success" />;
                }}
              />
              <Route
                exact
                path="/member/payerror"
                render={(props) => {
                  return <PayResultPage {...props} status="error" />;
                }}
              />
              <Route
                exact
                path="/member/paycancel"
                render={(props) => {
                  return <PayResultPage {...props} status="cancel" />;
                }}
              />
              <Route
                exact
                path="/member/help"
                render={(props) => {
                  return <HelpPage {...props} />;
                }}
              />
              <Route
                exact
                path="/member/selectgrouppage"
                render={(props) => {
                  return <SelectGroupPage 
                  localuser={this.state.localuser}
                  targetGroup={this.state.targetGroup}
                  groupMenuList={this.state.groupMenuList}
                  selectGroupFunc={this.selectGroupFunc}
                  {...props} />;
                }}
              />
              <Route
                render={(props) => {
                  return <NoMatchPage {...props} />;
                }}
              />
            </Switch>
          </div>
        </Router>
      );
    } else {
      return <CircularProgress size={56} className="buttonProgress" />;
    }
  }
}

export default RouterDiv;
